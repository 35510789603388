
export interface SharedServices {
    // URL per chiamate HTTP dove recuperare i METADATA
    host?: {
        url: string
    }
    // per la connessione alla coda NATS in EDGE
    nats?: Nats
    // per la connessione alla coda NATS in CLOUD
    cloudNats?: Nats
    /** tutti i componenti della FARM */
    components: Component[]
}

export interface Nats {
    host?: string | string[]
    port?: number
    username?: string // skip verification if null or empty
    password?: string
    enableTls?: boolean
    responseTimeout?: number
    subscribe: string
    publish: string
}

export interface Component {
    /** codice identificativo DEL COMPONENT */
    code?: string
    /** nome visualizzato sul FE */
    name?: string
    /** il super-type a cui appartiene questo 
     *  definisce anche dove collocare questo COMPONENT nella lista dei COMPONENTS del FE
     */
    group?: string,
    /** tags utilizzati per individuare il COMPONENT su description di un METADATA */
    tags?: string[]
    /** tipo di COMPONENT */
    type: COMPONENT_TYPE
    /** indica se il COMPONENT (true=) è disabilitato.
     * Se è disabilitato non sara' "cliccabile" nella lista del FE. 
     * Opzionale. Default=false */
    disabled?: boolean
    /** indica se il COMPONENT (true=) NON è presente nella lista del FE. 
	    Opzionale. Default=false */
    hidden?: boolean
    /** SUBJECTS NATS del COMPONENT */
    subjects?: string[]
    /** opzioni specifiche del COMPONENT */
    options?: OptionsBase
}

/**
 * Sono le opzioni specifiche di un COMPONENT
 */
export interface OptionsBase {
    grafana?: GrafanaOption[]
}

type GrafanaOption = {
    /** codice del punto sul FE dove appare il bottone di apertura */
    code: string,
    /** titolo sul DRAWER */
    title?: string,
    /** URL del grafico che appare sul DRAWER */
    url: string
}

/** CMPONENT di tipo GROW-UNIT */
export interface GrowUnitComponent extends Component {
    type: COMPONENT_TYPE.GROWUNIT
    options: {
        /** codice della GROW-UNIT */
        code: string
        /** descrittore dei TANKS */
        tanks?: {
            /** tipo di layout per i TANKS di irrigazione 
             * - "1:3-2" 
             * [WEGROW] 1 tank con 3 pompe di ingresso e 2 di scarico
             * Le pompe di scarico sono disattivabili
             * Nel sinottico IRRIGATION non ci sono le colonne: tutta LEFT MAX e PRESSURE OUTLET
             * - null 
             * [default] 6 tank con una valvola di ingresso e 1 di scarico 
             * */    
            irrigationTemplate?: "1:3-2" | null,
            waste?: string,
        }
        fans?: {
            /** non visualizzare la box della velocità (non si puo' regolare) */
            hideSpeed?: boolean,
            /** numero di torri che gestisce questo FAN */
            span?: number
            /** comando accensione spegnimento ventola
             * - false [default]
             * servono due alias: uno per leggere [INV_FAN_{index}_fQ_Run] e l'altro per scrivere [INV_FAN_{index}_fQ_Run]
             * - true 
             * il PLC si aspetta una sola variabile in lettura/scrittura [fQ_FAN_{index}] */
            aliasRW?: boolean
        }[]
    } & OptionsBase
}

export interface HVACComponent extends Component {
    type: COMPONENT_TYPE.HVAC
    options?: {
        template?: "no-chiller" | null,
        h2oPressHide?: boolean,
		saturationHide?: boolean,
    } & OptionsBase
}

export interface PowersComponent extends Component {
    type: COMPONENT_TYPE.POWERS
}

export interface GerminatorComponent extends Component {
    type: COMPONENT_TYPE.GERMINATOR
    options?: {
        template?: "tc:no-fans" | null
    } & OptionsBase
}

export interface OsmoticWaterComponent extends Component {
    type: COMPONENT_TYPE.OSMOTIC_WATER
    options?: {
        template?: "single-tank" | null
    } & OptionsBase
}


export enum COMPONENT_TYPE {
    GERMINATOR = "germinator",
    GROWUNIT = "grow-unit",
    HVAC = "hvac",
    POWERS = "powers",
    OSMOTIC_WATER = "osmotic-water",
    SERVICES = "services",
    CO2 = "co2",
}


export function componentTypeToString(type: COMPONENT_TYPE): string {
    return {
        [COMPONENT_TYPE.GERMINATOR]: "GERMINATOR",
        [COMPONENT_TYPE.GROWUNIT]: "GROW UNITS",
        [COMPONENT_TYPE.HVAC]: "SERVICES",
        [COMPONENT_TYPE.POWERS]: "POWERS",
        [COMPONENT_TYPE.SERVICES]: "SERVICES",
        [COMPONENT_TYPE.CO2]: "CO2",
    }[type]
}